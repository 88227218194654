.container {
    width: 100%;
    background-color: #2a266b;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.form {
    background-color: white;
    max-width: 400px;
    min-width: 351px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px
}

.error {
    font-size: 18px;
    color: red;
}

