.headerBurger {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 10;
}

/*!*for ios*!*/
/*@supports (padding: max(0px)) {*/
/*    .headerBurger {*/
/*        padding-left: max(12px, env(safe-area-inset-left));*/
/*        padding-right: max(12px, env(safe-area-inset-right));*/
/*    }*/
/*}*/

.headerBurger:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2a266b;
}


.header_btn__authBurger {
    margin: 0 20px 0 0;
}

@media (max-width: 576px) {
    .header_btn__authBurger {
        display: none;
    }
}


.header__menu-burger.active {
    top: 0;
}

.header__switch-lang {
    margin-left: 0;
}



.header__userPic .userPicStatus.online {
    background-color: #24aa42
}

.header__userPic .userPicStatus.offline {
    background-color: #EA2525
}

.header__activeLang {
    text-transform: uppercase;
    font-weight: 700;
}

.loader {
    width: 100%;
    height: 4px;
}
