.Modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    background: rgba(0 0 0 / 60%);;
    z-index: -1;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay {
    width: 100%;
    display: flex;
    justify-content: center;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.img {
    width: auto;
}

.content {
    border-radius: 12px;
    transition: 0.3s transform;
    transform: scale(0.5);
    height: auto;
    flex: 1 1 auto;
    margin: 0.5rem;
    background: white;
    overflow: auto;
}

.openedCalendar .content {
    transition: none;
    transform: none;
}

@media (max-width: 768px) {
    .contentMain {
        max-width: 100%;
        margin: 1.15rem auto;
    }
}

@media (min-width: 767px) {
    .contentMain {
        max-width: 600px;
        margin: 1.15rem auto;
    }
}

@media (min-width: 991px) {
    .contentMain {
        max-width: 700px;
        margin: 1.15rem auto;
    }
}

.img .contentMain {
    width: auto;
    max-width: none;
}

.contentCalendar {
    max-width: 100%;
    max-height: 100%;
    padding: 30px 20px 20px;
    margin: 40px;
}

@media (max-width: 1270px) {
    .contentCalendar {
        padding: 20px 10px 10px;
        margin: 20px;
    }
}

@media (min-width: 768px) {
    .contentVideo {
        max-width: 90vw;
        height: 90vh;
    }
}

.opened, .openedCalendar {
    pointer-events: auto;
    opacity: 1;
    z-index: 100;
    width: 100%;
}
.moreZIndex {
    z-index: 1400!important;
}
.notification {
    z-index: 1301;
    display: flex;
    align-items: center;
    justify-content: center;
}

.opened .content {
    transform: scale(1);
}

.isClosing .content {
    transform: scale(0.2);
}

.modalHead {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    padding: 0;
    margin-bottom: 0
}

.modalHead .close {
    cursor: pointer;
    position: absolute;
    z-index: 3;
    background-color: transparent;
    border: none;
    width: 18px;
    height: 18px;
    padding: 0;
    display: flex;
}

.modalHead .close svg {
    width: 18px;
    height: auto;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 1.1s;
    -o-transition: all 1.1s;
    transition: all 1.1s
}

.modalHead .close:hover svg {
    width: 18px;
    height: auto;
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: all 1.1s;
    -o-transition: all 1.1s;
    transition: all 1.1s
}

.modalHead .closeVideo {
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
}

.closeMain {
    top: 25px;
    right: 25px;
}

.closeCalendar {
    top: -10px;
    right: -10px;
}

@media (max-width: 1270px) {
    .closeCalendar {
        right: -3px;
    }
}
