.headerBurgerMain {
    display: none !important;
}

@media (max-width: 1270px) {
    .headerBurgerMain {
        display: block !important;;
    }
}

.headerMain {
    display: flex !important;;
}


@media (max-width: 1270px) {
    .headerMain {
        display: none !important;;
    }
}

.headerMain.header-out,  .headerBurgerMain.header-out{
    -webkit-transform: translateY(-92px);
    -ms-transform: translateY(-92px);
    transform: translateY(-92px);
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s
}



