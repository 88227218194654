.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.btn {
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    border: none;
    background-color: transparent;
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    -webkit-box-shadow: 0 2px 8px transparent;
    box-shadow: 0 2px 8px transparent;
    text-align: center;
    padding: 12px 64px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: max-content;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.btn.accent {
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    border: 1px dashed #FBC8DF;
    color: #ffffff;
    border-radius: 2px;
}

@media (max-width: 1400px) {
    .btn.accent {
        font-size: 14px;
    }
}

.btn.accent:hover {
    color: #2a266b;
    -webkit-box-shadow: 0 2px 8px rgba(219, 219, 219, 0.12);
    box-shadow: 0 2px 8px rgba(219, 219, 219, 0.12);
    background-color: #ffd6e8
}

.btn.pink:hover {
    -webkit-box-shadow: 0 2px 8px rgba(219, 219, 219, 0.12);
    box-shadow: 0 2px 8px rgba(219, 219, 219, 0.12);
    background-color: #ffd6e8
}

.btn.pink {
    color: #2a266b;
    background-color: #FBC8DF;
    border-radius: 2px;
}

.disabled {
    opacity: 0.6;
    color: rgba(234, 231, 231, 0.71) !important;
    cursor: default!important;
}

.hover:hover {
    color: #e4606d;
}
