.specialist__block .user__content-profile {
    flex-wrap: nowrap;
}

@media (max-width: 1200px) and (min-width: 769px) {
    .specialist__block .user__data-place {
        width: calc(100% - 270px);
    }
}

@media (max-width: 750px) and (min-width: 577px) {
    .specialist__block .user__data-place {
        padding: 0 10px 0 20px;
    }
}

.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
}

.each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}
