.btn {
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    -webkit-box-shadow: 0 2px 8px transparent;
    box-shadow: 0 2px 8px transparent;
    text-align: center;
    padding: 12px 64px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    min-width: 100px;
}

@media (max-width: 770px) {
    .btn {
        padding: 12px 40px;
    }
}

@media (max-width: 420px) {
    .btn {
        padding: 12px 15px;
    }
}


.btn:focus {
    outline: none;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s
}

.btn:hover {
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s
}

.btn.pink {
    color: #2a266b;
    background-color: #FBC8DF;
    border-radius: 2px;

}

.btn.pink:hover {
    -webkit-box-shadow: 0 2px 8px rgba(219, 219, 219, 0.12);
    box-shadow: 0 2px 8px rgba(219, 219, 219, 0.12);
    background-color: #ffd6e8
}

.btn.blue {
    color: #fff;
    background-color: #2a266b;
    border-radius: 2px
}


.btn.blue:hover {
    background-color: #393591
}

.loginScreen__form {
    margin: 32px auto 0;
    width: 85%;
}

.btn.big {
    padding: 24px 100px;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px
}

@media (max-width: 576px) {
    .btn.big {
        font-size: 26px;
        padding: 20px;
    }
}

.btn.w100 {
    width: 100%;
}

.btn.small {
    padding: 10px 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
}

.btn.medium {
    padding: 10px 63px;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
}

.btn.light {
    font-family: "Roboto", "Gilroy", "Century Gothic", sans-serif;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
    cursor: pointer;
    font-style: normal;
    /*font-weight: 300;*/
    /*font-size: 14px;*/
    /*line-height: 18px;*/
    /*padding-bottom: 2px;*/
    border-bottom: 1px solid transparent;
    color: #458FFF;
    -webkit-transition: all 0.2s;
    /*-o-transition: all 0.2s;*/
    /*transition: all 0.2s;*/
}
