.header {
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    background-color: #2a266b;
    z-index: 10;
    max-height: 92px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    padding: 16px;
    display: flex;
    justify-content: center;
}

/*!*for ios*!*/
/*@supports (padding: max(0px)) {*/
/*    .header {*/
/*        padding-left: max(12px, env(safe-area-inset-left));*/
/*        padding-right: max(12px, env(safe-area-inset-right));*/
/*    }*/
/*}*/


.header__wrp {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    box-sizing: border-box;
}

.header__menuWrp {
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.header__options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-left: 35px;
}

.header__menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-left: 40px;
}

.header__options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-left: 35px;
}

@media (max-width: 1400px) {
    .header__options {
        margin-left: 15px;
    }
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.header__authBox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.switchLang svg {
    margin-right: 8px;
}
.loader {
    position: absolute;
    bottom: 0;
    width: 100vw!important;
}
