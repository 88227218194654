
.tooltip {
    color: #fff;
    text-align: center;
    border-radius: 6px;
    font-size: 12px!important;
    cursor: pointer;
    white-space: normal;
    padding: 5px!important;
    z-index: 20;
    width: auto;
}

.tooltip_box{
    display: flex;
    align-items: center;
    justify-content: center;
}
