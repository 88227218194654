@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");


/*Обнуление*/
* {
    padding: 0;
    margin: 0;
    border: 0;
}

*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

:focus, :active {
    outline: none;
}

a:focus, a:active {
    outline: none;
}

nav, footer, header, aside {
    display: block;
}

input, button, textarea {
    font-family: inherit;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a, a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul li {
    list-style: none;
}

img {
    vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: 400;
}


input[type="checkbox" i] {
    background-color: initial;
    cursor: default;
    appearance: auto;
    box-sizing: border-box;
    margin: 3px 3px 3px 4px;
    padding: initial;
    border: initial;
}
/*--------------------*/

.bigred {
    font-size: 20px;
    color: red;
}

*, *::before, *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

a {
    text-decoration: none;
    cursor: pointer
}

body {
    font-family: "Century Gothic", "Roboto", "Gilroy", sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #272727;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #FCFCFC;
    overflow-x: hidden;
    opacity: 1;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

main {
    min-height: 100%;
}

body.no-scroll {
    overflow: hidden
}

body::-webkit-scrollbar-button {
    -webkit-transition: .2s !important;
    transition: .2s !important;
    width: 5px;
    height: 0
}

body::-webkit-scrollbar-track {
    background: #f7f7f7
}

body::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #272727
}

body::-webkit-resizer {
    width: 5px;
    height: 5px
}

body::-webkit-scrollbar {
    width: 5px
}

a {
    text-decoration: none;
    color: inherit;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s
}

a:hover {
    text-decoration: none;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s
}

h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
    font-size: inherit
}

input, button, textarea {
    outline: none
}

h1 {
    font-size: 2.5rem
}

h2 {
    font-size: 2rem
}

h3, h4, h5, h6 {
    font-size: 1.5rem
}

.adlery {
    font-family: "Adlery-Pro-trial", "Arial", sans-serif
}

.century {
    font-family: "Century Gothic", "Arial", sans-serif
}

.gilroy {
    font-family: "Gilroy", "Century Gothic", "Arial", sans-serif
}

button {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer
}


@font-face {
    font-family: 'themify';
    src: url("../public/fonts/themify.woff");
    src: url("../public/fonts/themify.woff") format("woff"), url("../public/fonts/themify.ttf") format("truetype"), url("../public/fonts/themify.svg") format("svg");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    src: local("Century Gothic"), url("../public/fonts/CenturyGothic.woff2") format("woff2")
}

@font-face {
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 700;
    src: local("Century Gothic Bold"), url("../public/fonts/CenturyGothic-Bold.woff2") format("woff2")
}

@font-face {
    font-family: 'Adlery';
    font-style: normal;
    font-weight: 500;
    src: local("Adlery-Pro-trial"), url("../public/fonts/Adlery-Pro-trial.ttf") format("truetype")
}

@font-face {
    font-family: 'Swash';
    font-style: normal;
    font-weight: 500;
    src: local("Adlery-Swash-trial"), url("../public/fonts/Adlery-Swash-trial.ttf") format("truetype")
}

@font-face {
    font-family: 'Blockletter';
    font-style: normal;
    font-weight: 500;
    src: local("Adlery-Pro-Blockletter-trial"), url("../public/fonts/Adlery-Pro-Blockletter-trial.ttf") format("truetype")
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    src: local("Gilroy"), url("../public/fonts/Gilroy-Regular.woff2") format("woff2")
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    src: local("Gilroy"), url("../public/fonts/Gilroy-Semibold.woff2") format("woff2")
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 900;
    src: local("Gilroy"), url("../public/fonts/Gilroy-Bold.woff2") format("woff2")
}

/*navbar show*/

@media (max-width: 1270px) {
    .headerBurger {
        display: block;
    }
}

#root {
    height: 100%;
    overflow: auto;
}
