.modal-body {
    padding: 32px 64px;
}

@media (min-width: 470px) and (max-width: 575px) {
    .modal-body {
        padding: 32px 40px 32px;
    }
}

@media (max-width: 470px) {
    .modal-body {
        padding: 20px 15px;
    }
}

.create-chat__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #2a266b;
    margin-bottom: 32px;
    text-align: center;
}

@media (max-width: 470px) {
    .create-chat__title {
        font-size: 25px;
        line-height: 26px;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        padding-right: 30px;
    }
}

.text {
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    font-weight: normal;
}

.text p {
    font-family: "Roboto", "Gilroy", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 15px;
}

@media (max-width: 470px) {
    .text p {
        font-size: 14px;
    }
}

.text p:last-child {
    margin-bottom: 0;
}

/*animate show modal*/
.modal-enter {
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transform: scale(0.5);
}

.modal-enter-active {
    opacity: 1;
    pointer-events: all;
    z-index: 100;
    transition:2s;
    transform: scale(1);
}

.modal-exit {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
}

.modal-exit-active {
    opacity: 0;
    pointer-events: none;
    z-index: -1;
    transition: 0.3s;
    transform: scale(0.5);
}

.noFile {
    font-family: "Roboto", "Gilroy", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #272727;
}
