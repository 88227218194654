.chats__header {
    padding: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 32px;
    position: relative;
    gap: 10px;
}

@media (max-width: 770px) {
    .chats__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .chats__header {
        margin-bottom: 0;
    }
}

@media (max-width: 575px) {
    .chats__header {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.chats__headerInfo {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 60%;
}

@media (max-width: 770px) {
    .chats__headerInfo {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.chats__headerBtns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media (max-width: 770px) {
    .chats__headerBtns button {
        margin: 10px 0;
    }
}

.chats__headerTitle {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 80px;
    color: #272727;
    margin-bottom: 16px;
}

@media (max-width: 768px) {
    .chats__headerTitle {
        font-size: 48px;
        line-height: 58px;
    }
}

@media (max-width: 580px) {
    .chats__headerTitle {
        font-size: 45px;
        line-height: 50px;
    }
}

.chats__headerComment {
    font-family: "Roboto", "Gilroy", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #959595
}

.chatItem__hover {
    transition: transform .4s;
    cursor: pointer;
}

.chatItem__hover:hover {
    transform: scale(1.05);
    box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}


.tabContentFilter {
    margin-top: 36px;
}

.row__chatContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

