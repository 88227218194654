.Snackbar {
    cursor: pointer;
}

.message {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user {
    color: #2a266b;
    font-weight: 600;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
    margin-left: 5px;
}
.text {
    margin-left: 5px;
}
