.header__logoutBlock button {
    display: flex;
    align-items: center;
}


.header__logout {
    margin-right: 32px
}

@media (max-width: 1400px) {
    .header__logout {
        margin-right: 0
    }
}

.header__menuBurger .header__link {
    font-size: 18px;
    line-height: 28px;
}

.header__link {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 2px;
    /*min-width: 80px;*/
}
