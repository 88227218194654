.footer {
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    background-color: #2a266b;
    padding: 16px 0;
    color: #fff;
    margin-top: auto;
    z-index: 2;
}

.footer__topLine {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: 1px solid #ededed;
}

.footer__logoLink {
    display: block;
    height: 32px;
}

.footer__logoLink img {
    height: 100%;
    width: auto;
}

.footer .topLineMenu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: auto;
    max-width: 70%;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px
}

@media (max-width: 400px) {
    .footer .topLineMenu {
        justify-content: flex-end;
    }
}

.footer .topLineMenu__link {
    padding: 8px 0 8px 16px;
    text-align: right;
}

.footer .topLineMenu__link:hover {
    color: #FBC8DF
}

.footer__bottomLine {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 8px
}

@media (max-width: 575px) {
    .footer__bottomLine {
        flex-direction: column;
    }
}

.footer__copyright {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-top: 8px;
    margin-right: 80px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap
}

@media (max-width: 400px) {
    .footer__copyright {
        margin-right: 0;
    }
}

.footer .bottomLineMenu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-top: 8px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px
}

@media (max-width: 508px) {
    .footer .bottomLineMenu {
        justify-content: left;
    }
}

.footer .bottomLineMenu__link {
    padding: 4px 0 4px 16px
}

.footer .bottomLineMenu__link:hover {
    color: #FBC8DF
}
