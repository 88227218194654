.contentScreen__line {
    margin: 0 auto 100px;
}

@media (max-width: 768px) {
    .contentScreen__line {
        margin-bottom: 40px;
    }
}

@media (min-width: 992px) {
    .colLg8 {
        -ms-flex: 0 0 66.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
}


@media (min-width: 992px) {
    .colLg4 {
        -ms-flex: 0 0 33.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

@media (max-width: 992px) {
    .orderSm1 {
        order: 2;
    }
}

@media (max-width: 992px) {
    .order1 {
        display: flex;
        flex-direction: row;
        max-width: 100%;
    }
}

@media (max-width: 992px) {
    .orderSm1 {
        order: 2;
    }
}

.colLg_6 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}


@media (min-width: 992px) {
    .colLg_6 {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width: 768px) {
    .loginScreen {
        padding-bottom: 20px;
    }
}

@media (max-width: 996px) {
    .order__1 {
        order: 1;
    }
    .order__2 {
        order: 2;
    }
}


@media (min-width: 768px) {
    .colMd_12 {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.chats__area {
    position: relative;
}

.chats__header {
    padding: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 32px;
    position: relative;
}

@media (max-width: 768px) {
    .chats__header {
        margin-bottom: 0;
    }
}

@media (max-width: 575px) {
    .chats__header {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.chats {
    height: 100%;
}

.row__chat-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.navigation__container {
    position: relative;
}
