.profileSettings__desktop .header__notificationBox {
    top: 56px;
}

.marker {
    color: #2a266b;
    font-weight: 500;
}

.header__notificationBox {
    position: absolute;
    background-color: #fff;
    width: 425px;
    left: -200px;
    top: 49px;
}

@media (max-width: 1270px) {
    .header__notificationBox {
        top: 39px;
        left: -339px;
    }
}

@media (max-width: 768px) {
    .header__notificationBox {
        left: 50%;
        right: -10px;
        top: 50px;
        width: auto;
    }
}

@media (max-width: 576px) {
    .header__notificationBox {
        left: -10px;
    }
}

.notiWrp__item {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 16px;
    text-align: left;
    font-family: "Roboto", "Gilroy", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #272727;
    border-top: 1px solid #EDEDED;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    border-left: 3px solid transparent;
}

@media (max-width: 576px) {
    .notiWrp__item {
        font-size: 14px;
        padding: 10px 5px;
    }
}

.notiWrp__item:hover {
    border-left: 3px solid #2a266b;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.notiWrp__item p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.count {
    color: #fff;
    background-color: #2a266b;
    border-radius: 30px;
    padding: 2px 8px;
}

.countStart {
    color: #fff;
    background-color: #00BA34;
    border-radius: 30px;
    padding: 2px 8px;
    min-width: 24px;
    min-height: 24px;
}

.accept {
    color: #00BA34;
}

.decline {
    color: #dc040b;
}

.messageBlock {
    display: flex;
    gap: 5px;
}

.user {
    font-size: 14px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #2a266b;
}

.hover:hover {
    color: #FBC8DF
}

.conference {
    color: #00BA34
}

.loading {
    opacity: 0.5;
}

.start {
    border: 5px solid #00BA34;
    background-color: rgba(2, 248, 52, 0.16);
}

.sessionStartBox {
    margin-right: 10px;
}
