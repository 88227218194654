.header__item {
    margin-right: 30px;
    white-space: nowrap;
}

.header__item:last-child {
    margin-right: 0
}

.header__link {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 2px;
    min-width: 80px;
}

@media (max-width: 1400px) {
    .header__link {
        font-size: 14px;
    }
}

.header__menuBurger .header__link {
    font-size: 18px;
    line-height: 28px;
}

.header__link:hover {
    color: #FBC8DF
}