.test {
    height: 100%;
    background-color: #FFFFFF;
}

.test__wrp {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.custom-select__input-container {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.custom-select__single-value {
    padding-bottom: 2px;
}
