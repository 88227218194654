.user__mood {
    display: block;
    margin-left: 16px;
    position: relative;
}

.user__activeMood {
    display: block;
    cursor: pointer;
    width: 34px;
    height: 34px;
}
