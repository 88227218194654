

@media (max-width: 510px) {
    .header__user-name {
        display: none;
    }
}

.profile {
    margin-right: 6px;
}

.header__notificationLabel {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.notiCount {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    top: -1px;
    right: -1px;
    background-color: #EA2525
}

.header__userPic {
    width: 40px;
    height: 40px;
    position: relative
}

@media (max-width: 576px) {
    .header-burger .header__userPic {
        display: block;
    }
}

.header__userPic img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1200px) {
    .header__userPic img {
        width: 40px;
        height: 40px;
    }
}

.header__userPic .userPicStatus.online {
    background-color: #24aa42
}

.header__userPic .userPicStatus.offline {
    background-color: #EA2525
}

.header__userPic .userPicStatus {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    bottom: -1px;
    right: -1px;
}

.header__userName {
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    max-width: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-left: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 576px) {
   .header__userName {
        display: none;
    }
}
