.user {
    height: 100%;
}

.user__wrp {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.user__content {
    width: calc(100% - 340px);
}

@media (max-width: 992px) {
    .user__content {
        width: 100%;
    }
}

.user__content-profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
}

@media (max-width: 1200px) {
    .user__content-profile {
        flex-wrap: nowrap;
    }
}

@media (max-width: 675px) {
    .user__content-profile {
        justify-content: center;
        flex-wrap: wrap;
    }
}

.user__avatar-place {
    width: 250px;
}

@media (min-width: 675px) {
    .user__avatar-place {
        margin-right: 20px;
    }
}

@media (max-width: 460px) {
    .user__avatar-place {
        width: 150px;
    }
}

.user__data-place {
    width: calc(100% - 270px);
}

@media (max-width: 1260px) {
    .user__data-place {
        width: 100%;
        padding-left: 10px;
    }
}

@media (max-width: 768px) {
    .user__data-place {
        padding-left: 0;
    }
}

.user__data-place.full-width {
    width: 100%;
}

.user__avatar-place img {
    width: 100%;
    height: auto;
    -o-object-fit: contain;
    object-fit: contain;
}

.user__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 16px;
}

.user__info {
    display: block;
    margin-bottom: 48px;
}


.user__title-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.user__title .user__title-box {
    flex-direction: row;
}

@media (max-width: 768px) {
    .user__title-box {
        align-items: center;
        text-align: center;
    }
}

.user__settings-link {
    display: block;
    margin-left: 16px;
}

.user__title h2 {
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #272727;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*animate show modal*/
.popover-enter {
    opacity: 0;
}

.popover-enter-active {
    opacity: 1;
    transition: 0.4s;
}

.popover-exit {
    opacity: 1;
}

.popover-exit-active {
    opacity: 0;
    transition: 0.4s;
}

.user__title-styling {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 32px;
}

@media (min-width: 1201px) {
    .user__title-styling {
        flex-wrap: wrap;
        gap: 20px;
    }
}

@media (max-width: 1200px) {
    .user__title-styling {
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
    }
}

@media (max-width: 830px) {
    .user .user__title-styling {
        margin-bottom: 40px;
    }
}

@media (max-width: 576px) {
    .user .user__title-styling {
        margin-bottom: 20px;
    }
}

.user__title-styling h1, .user__title-styling h2, .user__title-styling h3, .user__title-styling h4 {
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 80px;
    color: #272727;
}

@media (max-width: 992px) and (min-width: 768px) {
    .user__title-styling h2 {
        font-size: 48px;
    }
}

@media (max-width: 768px) {
    .user__title-styling h2 {
        font-size: 36px;
        line-height: 48px;
    }
}

@media (max-width: 576px) {
    .user__title-styling h2 {
        font-size: 26px;
        line-height: 48px;
    }
}

.title-marker {
    background: #FFF4F9;
    border-radius: 2px;
    color: #2A266B;
    padding: 0 10px;
}

.search-box {
    width: 320px;
    margin-bottom: 16px;
    margin-left: 20px;
}

.no-friends {
    padding: 34px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #959595;
    gap: 10px;
}

.user__search {
    margin-bottom: 40px;
}

.text {
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    font-weight: normal;
}

.user__cabinet-tabs {
    margin-top: 48px;
}

@media (max-width: 576px) {
    .user__cabinet-tabs {
        margin-top: 25px;
    }
}

.text p:last-child {
    margin-bottom: 0;
}

.text p {

}

.text p {
    font-family: "Roboto", "Gilroy", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 15px;
}

.user__title-box.styling-line {
    background-color: #FFF4F9;
    padding: 20px 32px;
    width: 100%;
}

@media (max-width: 576px) {
    .user__title-box.styling-line {
        padding: 10px;
    }
}

.user__title-box.styling-line h2 {
    line-height: 40px;
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: #2a266b;
}

@media (max-width: 568px) {
    .user__title-box.styling-line h2 {
        font-size: 26px;
    }
}

.settings {
    width: 90%;
    margin-bottom: 40px;
}

@media (max-width: 768px) {
    .settings {
        width: 100%;
    }
}

.settings__avatar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.settings__current-avatar {
    display: block;
    width: 64px;
    height: 64px;
    margin-right: 24px;
}

@media (max-width: 1200px) and (min-width: 577px) {
    .settings__current-avatar {
        width: 100px;
        height: 100px;
    }
}

.settings__current-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
}

.settings__accordion {
    margin-top: 40px;
    width: 100%;
}

@media (max-width: 576px) {
    .settings__accordion {
        margin-top: 20px;
    }
}


/*animation changes block in Profile settings*/

.changes-enter {
    transform: translateY(-20%);
}

.changes-enter-active {
    transform: translateY(0%);
    transition: 0.3s;
}

.changes-exit {
    transform: translateY(0%);
}

.changes-exit-active {
    transition: 0.3s;
    transform: translateY(-20%);
}


/*animation showing mood block in Profile */
.moods-enter {
    transform: scaleY(0) translateY(-20%);
}

.moods-enter-active {
    transform-origin: top;
    transform: scaleY(1) translateY(0%);
    transition: 0.3s;
}

.moods-exit {
    transform: scaleY(1) translateY(0%);
}

.moods-exit-active {
    transition: 0.3s;
    transform-origin: top;
    transform: scaleY(0) translateY(-20%);
}

.hide__block {
    visibility: hidden;
    width: 0 !important;
    height: 0 !important;
    transition-duration: 200ms;
}

.welcome-specialist {
    position: relative;
    z-index: 1;
    width: 250px;
}

.user .welcome-specialist img {
    width: 100%;
    height: 200px;
    -o-object-fit: cover;
    object-fit: cover;
}

.welcome-specialist-info {
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #FCFCFC;
    text-shadow: 0 4px 8px rgb(255 255 255 / 24%);
    position: absolute;
    height: 200px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.32);
    width: 100%;
    padding: 16px;
    border-radius: 2px;
}

.welcome-specialist-info .play-player {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
}

.welcome-specialist-info .play-player {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
}

.play-player svg {
    width: 60px;
    height: 60px;
}
