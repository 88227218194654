.Text {
    font-family: "Roboto", "Gilroy", "Century Gothic", sans-serif;
    margin-top: 10px;
    font-weight: 400;
}

.text {
}

.error {
    color: red;
}

.left {

}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.size12 {
    font-size: 12px;
}

.size14 {
    font-size: 14px;
}

.size16 {
    font-size: 16px;
}

.size18 {
    font-size: 18px;
}

.light {
    color: #959595;
}

.successful {
    color: #00BA34;
}

.notification {

}
