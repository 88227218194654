.header__item {
    margin-right: 30px;
    white-space: nowrap;
}

.header__item:last-child {
    margin-right: 0
}

@media (max-width: 1910px) {
    .header__item {
        margin-right: 30px
    }
}

@media (max-width: 1706px) {
    .header__item {
        margin-right: 20px
    }
}

.header__link {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 2px;
    min-width: 80px;
}

.header__link:hover {
    color: #FBC8DF
}