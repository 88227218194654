.container {
    width: 100%;
    height: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding: 150px 15px;
}

@media (max-width: 1270px) {
    .container {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

@media (max-width: 768px) {
    .container {
        padding-top: 100px;
        padding-bottom: 80px;
    }
}

@media (min-width: 768px) {
    .container {
        padding-right: 25px;
        padding-left: 25px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 970px
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1240px
    }
}

@media (min-width: 1680px) {
    .container {
        max-width: 1330px
    }
}

.main {
    padding-bottom: 0;
    padding-top: 0;
}

/*.fluid {*/
/*    max-width: 1300px;*/
/*    padding-right: 15px;*/
/*    padding-left: 15px;*/
/*    margin-right: auto;*/
/*    margin-left: auto;*/
/*    padding-top: 150px;*/
/*}*/

.noVerticalPadding {
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 15px;
}

/*.profile {*/
/*    padding: 160px 50px 80px;*/
/*    max-width: 1300px;*/
/*    margin-right: auto;*/
/*    margin-left: auto;*/
/*    height: 100%;*/
/*}*/

/*@media (max-width: 1200px) {*/
/*    .profile {*/
/*        padding: 100px 0 80px;*/
/*    }*/
/*}*/

/*.test {*/
/*    padding: 160px 0 80px;*/
/*    height: 100%;*/
/*    min-height: calc(100vh - 200px);*/
/*}*/

/*@media (max-width: 1200px) and (min-width: 684px) {*/
/*    .test {*/
/*        padding-top: 100px;*/
/*        padding-bottom: 40px;*/
/*    }*/
/*}*/

/*@media (max-width: 683px) {*/
/*    .test {*/
/*        padding-top: 80px;*/
/*        padding-bottom: 20px;*/
/*    }*/
/*}*/

/*.screen {*/
/*    padding: 160px 0;*/
/*    border: 1px solid blue;*/
/*}*/

/*@media (max-width: 950px) and (min-width: 571px) {*/
/*    .screen {*/
/*        padding: 80px 0;*/
/*    }*/
/*}*/

/*@media (max-width: 570px) {*/
/*    .screen {*/
/*        padding: 60px 0;*/
/*    }*/
/*}*/

.crumbs {
    position: relative;
}
.chat {
    padding-top: 100px;
    padding-bottom: 0;
}


@media (max-width: 1270px) {
    .chat {
        padding-top: 85px;
    }
}

@media (max-width: 768px) {
    .chat {
        padding: 55px 0 0;
    }
}

.trigger {
    height: 20px;
    margin-top: 10px;
    background-color: red;
}
