.MuiGrid-root {
    display: flex;
    gap: 20px;
    align-items: center;
}

.Layout-root {
    flex: 1 !important;
    max-width: 100% !important;
}

.css-1vam7s3-MuiGrid-root {
    display: none !important;
}

s.Layout-booleanEditors {
    display: none !important;
}

.contentCalendar {
    max-width: 100%;
    max-height: 100%;
    margin: 40px;
}

.MainLayout-dayScaleEmptyCell {
    min-width: 81px !important;
}

@media (max-width: 768px) {
    .MainLayout-dayScaleEmptyCell {
        min-width: 49.5px !important;
    }
}

@media (max-width: 768px) {
    .Layout-timeScaleContainer {
        width: 40px !important;
    }
}

@media (max-width: 1250px) {
    .Layout-fullSize {
        width: 1050px !important;
    }

    /*.css-vr9er9-MuiModal-root-MuiDrawer-root .Overlay-fullSize {*/
    /*    width: 1050px !important;*/
    /*}*/
}


@media (max-width: 1100px) {
    .css-nupfgw-MuiGrid-root.Layout-fullSize {
        width: 940px !important;
    }

    /*.css-vr9er9-MuiModal-root-MuiDrawer-root .Overlay-fullSize {*/
    /*    width: 940px !important;*/
    /*}*/
}

@media (max-width: 1000px) {
    .css-nupfgw-MuiGrid-root.Layout-fullSize {
        width: 860px !important;
    }

    /*.css-vr9er9-MuiModal-root-MuiDrawer-root .Overlay-fullSize {*/
    /*    width: 860px !important;*/
    /*}*/
}


.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    height: auto !important;
}

@media (max-width: 900px) {
    .Layout-fullSize {
        width: auto !important;
        max-width: 100%;
    }

    /*.Overlay-fullSize {*/
    /*    width: auto !important;*/
    /*    max-width: 100%;*/
    /*}*/
}

.Layout-halfSize {
    width: auto !important;
}
.Overlay-halfSize, .Overlay-fullSize{
    width: 650px!important;
}

@media (max-width: 768px) {
    .MainLayout-leftPanel {
        width: 49px!important;
    }
}
.Layout-root {
    overflow-y: hidden!important;
}

.manContainer {
    height: calc(100vh - 140px);
}
