.user__contentProfile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
}

@media (max-width: 1200px) {
    .user__contentProfile {
        flex-wrap: nowrap;
    }
}

@media (max-width: 768px) {
    .user__contentProfile {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.user__avatarPlace {
    width: 250px;
}

@media (min-width: 675px) {
    .user__avatarPlace {
        margin-right: 20px;
    }
}

@media (max-width: 460px) {
    .user__avatarPlace {
        width: 150px;
    }
}

.user__avatarPlace img {
    width: 100%;
    height: auto;
    max-height: 250px;
    -o-object-fit: contain;
    object-fit: contain;
}

.profile__userBtns {
    margin-top: 10px;
}

.profile__userBtns button {
    width: 100% !important;
    max-width: 100%;
    margin-bottom: 16px;
    padding: 12px !important;
}

.profile__userBtns button:first-child {
    margin-top: 16px;
}

.price {
    margin-top: 16px;
    margin-bottom: 16px;
}

.hidden {
    overflow: hidden;
}
