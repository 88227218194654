.user__info {
    display: block;
    margin-bottom: 48px;
}

.user__infoLine {
    margin-bottom: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.user__infoName {
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #959595;
    width: -moz-min-content;
    width: min-content;
    display: block;
    white-space: nowrap;
    margin-right: 16px;
}

@media (max-width: 460px) {
    .user__infoName {
        font-size: 14px;
    }
}

.user__infoData {
    font-family: "Roboto", "Gilroy", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #272727;
}

@media (max-width: 460px) {
    .user__infoData {
        font-size: 14px;
    }
}
