.chats__body .chatItem {
    background-color: #fff;
}

.chat__container {
    width: calc(50% - 20px);
    margin-bottom: 0;
}

.cursor__pointer {
    cursor: pointer;
}

.chatItem__hover {
    transition: transform .4s;
}

.chatItem__hover:hover {
    transform: scale(1.05);
    box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}

.chatItem {
    position: relative;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
    box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
    padding: 30px 16px 16px 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.chatItem.chat {
    background-color: #FFFCF2;
}

@media (max-width: 1200px) {
    .chatItem {
        padding: 25px 10px 16px 10px;
    }
}

@media (max-width: 991px) {
    .chat__container {
        width: 100%;
    }
}

.chatItem__block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}



.chatItem__image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 16px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 5px;
}

@media (max-width: 991px) {
    .chatItem__image {
        margin-top: 23px;
    }
}

@media (max-width: 560px) {
    .chatItem__image {
        align-items: flex-start;
        padding-top: 4px;
        margin-right: 10px;
    }
}

.chatItem__image img {
    width: 84px;
    height: 84px;
    border-radius: 50%;
    object-fit: cover;
}

@media (max-width: 1200px) {
    .chatItem__image img {
        width: 70px;
        height: 70px;
    }
}

@media (max-width: 720px) and (min-width: 560px) {
    .chatItem__image img {
        width: 46px;
        height: 46px;
    }
}

@media (max-width: 560px) {
    .chatItem__image img {
        width: 40px;
        height: 40px;
    }
}

.chatItem__info {
    width: 100%;
}

.chatItem__name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #272727;
    margin: 8px 0;
}

@media (max-width: 1200px) {
    .chatItem__name {
        font-size: 16px;
    }
}

@media (max-width: 990px) {
    .chatItem__name {
        margin-top: 20px;
    }
}

.chatItem__name span {
    display: block;
    width: 100%;
}

@media (max-width: 560px) {
    .chatItem__name span {
        font-size: 16px;
    }
}

.count_box {
    display: flex;
}

.chatItem__count:first-child {
    margin-right: 3px;
}

.chatItem__count {
    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-block;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 6px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background: #FFF4F9;
    border-radius: 2px;
    color: #2a266b;
    margin-bottom: 8px;
}

@media (max-width: 576px) {
    .chatItem__count {
        margin-right: 0;
    }
}

.chatItem__description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #959595;
    margin-bottom: 12px;
}

.chatItem__options {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

@media (max-width: 768px) {
    .chatItem__options {
        align-items: flex-start;
    }
}

.chatItem__date {
    color: #959595;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: flex-end;
    height: 100%;
}


/*main-page class*/
.main__page.chatItem {
    margin-bottom: 32px;
}

@media (min-width: 992px) {
    .main__page.chatItem {
        min-height: 222px;
        align-items: center;
    }
}

@media (min-width: 992px) {
    .main__page .chatItem__image {
        margin-top: -75px;
    }
}


@media (min-width: 992px) {
    .main__page .chatItem__name {
        min-height: 48px;
    }
}

@media (min-width: 992px) {
    .main__page .chatItem__description {
        min-height: 32px;
    }
}

@media (max-width: 768px) {
    .main__page .chatItem__options {
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}


.chatItem {
    background-color: #fff;
}

.chatItem {
    position: relative;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
    box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
    padding: 30px 16px 16px 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

@media (max-width: 1200px) {
    .chatItem {
        padding: 25px 10px 16px 10px;
    }
}

.chatItem__hover {
    transition: transform .4s;
}

.chatItem__hover:hover {
    transform: scale(1.05);
    box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}


.chat__container {
    width: calc(50% - 20px);
    margin-bottom: 0;
}

@media (max-width: 991px) {
    .chat__container {
        width: 100%;
    }
}

.chatItem.room {
    background-color: #E1FFE4;
}

.chatItem__btns {
    width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}

@media (max-width: 768px) {
    .chatItem__btns {
        justify-content: flex-start;
        gap: 10px;
    }
}

.chat__container .chatItem__info .chatItem__btns {
    margin-right: 0;
}

.lineGray {
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0;
}

@media (max-width: 576px) {
    .lineGray {
        margin-left: 0;
    }
}

.lineGray.green {
    color: #24aa42;
}

.lineGray.red {
    color: #EA2525;
}

@media (max-width: 1270px) {
    .chat-profile__container {
        width: 100%;
    }
}

.update-chat {
    font-family: "Roboto", "Gilroy", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    cursor: default;
    margin-right: 10px;
    margin-left: 8px;
    color: #458FFF;
}

.loading {
    opacity: 0.6;
}
