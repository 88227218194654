.activity__title {
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 60px;
    text-align: center;
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 80px;
    color: #272727;
    margin-top: 20px;
}

@media (max-width: 991px) and (min-width: 571px) {
    .activity__title {
        margin-bottom: 40px;
    }
}

@media (max-width: 570px) and (min-width: 451px) {
    .activity__title {
        font-size: 52px;
        margin-bottom: 20px;
    }
}

@media (max-width: 450px) {
    .activity__title {
        font-size: 42px;
        line-height: 60px;
        margin: 30px 0 10px;
    }
}

.title__marker {
    background: #FFF4F9;
    border-radius: 2px;
    color: #2A266B;
    padding: 0 10px;
}

.w100 {
    width: 100%;
}
