.mood {
    display: block;
    position: absolute;
    top: calc(100% + 14px);
    background: #FFFFFF;
    -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 12%);
    box-shadow: 0 2px 8px rgb(0 0 0 / 12%);
    border-radius: 2px;
    padding: 16px;
}

@media (max-width: 1270px) {
    .mood {
        left: -140px;
    }
}

@media (max-width: 800px) and (min-width: 677px) {
    .mood {
        left: -70px;
    }
}

@media (max-width: 676px) {
    .user__title .mood {
        left: -110px;
    }
}

@media (max-width: 480px) {
    .user__title .mood {
        width: calc(100vw - 25px);
        left: -150px;
    }
}

.mood__title {
    font-family: "Roboto", "Gilroy", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #272727;
    margin-bottom: 16px;
}

.mood__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

@media (max-width: 480px) {
    .mood__list {
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
    }
}