.containerBurger {
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 10px;
}

.header__bodyBurger {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
}
.header__btnsBurger {
    display: flex;
    width: 100%;
    justify-content: right;
    align-items: center;
}


.header__burger.active:before {
    transform: rotate(45deg);
    top: 9px;
    transition: all 0.3s ease 0s;
}

.header__burger.active:after {
    transform: rotate(-45deg);
    bottom: 9px;
    transition: all 0.3s ease 0s;
}

.header__burger.active span {
    transform: scale(0);
}

.header__burger {
    display: block;
    position: relative;
    min-width: 30px;
    min-height: 20px;
    z-index: 3;
}

.header__burger span {
    position: absolute;
    background-color: #fff;
    left: 0;
    width: 100%;
    height: 2px;
    top: 9px;
}

.header__burger:before,
.header__burger:after {
    content: '';
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transition: all 0.3s ease 0s;
}

.header__burger:before {
    top: 0
}

.header__burger:after {
    bottom: 0;
}

.header__burger.active:before {
    transform: rotate(45deg);
    top: 9px;
    transition: all 0.3s ease 0s;
}

.header__burger.active:after {
    transform: rotate(-45deg);
    bottom: 9px;
    transition: all 0.3s ease 0s;
}

.header__burger.active span {
    transform: scale(0);
}

.btnRegistration {
    font-weight: 600;
    font-size: 16px;
}

@media (max-width: 576px) {
    .btnRegistration {
        display: none;
    }

}

.header_btn__authBurger {
    padding-right: 10px;
}
