.header__notifications {
    margin: 0 15px;
    position: relative;
}

@media (max-width: 1910px) {
    .header__notifications {
        margin: 0 20px
    }
}

@media (max-width: 576px) {
    .header__notifications {
        margin: 0 10px;
    }
}

@media (max-width: 768px) {
    .header__notifications {
        position: static;
    }
}


