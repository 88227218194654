.price {
    font-family: "Roboto", "Gilroy", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 24px;
    text-align: right;
    color: #458FFF;
    display: flex;
    align-items: center;
    gap: 5px;
    position: sticky;
}

.price.center {
    justify-content: center;
}

.price.end {
    justify-content: right;
}

.price.start {
    justify-content: left;
}
