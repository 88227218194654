
.header__menuBurger {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #2a266b;
    z-index: -1;
    padding: 70px 10px 20px 0;
}

@media (max-width: 768px) {
    .header__menuBurger {
        right: 50%;
    }
}

@media (max-width: 576px) {
    .header__menuBurger {
        width: 100%;
        height: 100%;
    }
}

/*.header__menuBurger.active {*/
/*    top: 0;*/
/*}*/

.header__listBurger {
    display: block;
    list-style: none;
    margin: 10px 0;
    z-index: 5;
    color: #FFF4F9;
}

.header__listBurger {
    padding-left: 20px;
}

.header__listBurger li {
    list-style: none;
    margin: 10px 0;
}

.btn__authBurger {
    display: none;
}

@media (max-width: 576px) {
    .btn__authBurger {
        display: block;
    }
}

.language_block {
    display: flex;
    align-items: center;
}

.language_block svg {
    margin-right: 5px;
}

.switchLang {
    margin-left: 0!important;
}
