.notiCount {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    top: -1px;
    right: -1px;
    background-color: #EA2525
}

.header__notificationLabel {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}
