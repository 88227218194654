.inputEffect {
    position: relative;
    font-family: "Roboto", "Gilroy", "Century Gothic", sans-serif;
    font-weight: normal;
}

@media (max-width: 576px) {
    .inputEffect {
        width: 100%;
    }
}

.inputEffect {
    position: relative;
    font-family: "Roboto", "Gilroy", "Century Gothic", sans-serif;
    font-weight: normal;
}

.inputCustom {
    padding: 16px 8px 8px 8px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    background-color: transparent;
    border: none;
    color: #272727;
    border-bottom: 2px solid #ededed;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.inputCustom ~ .focusBorder {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #2a266b;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s
}

.inputCustom:focus ~ .focusBorder, .hasContent.inputCustom ~ .focusBorder {
    width: 100%;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    left: 0
}

.inputCustom ~ label {
    font-size: 18px;
    position: absolute;
    left: 8px;
    width: 100%;
    top: 16px;
    color: #959595;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    z-index: 0;
    letter-spacing: 0.5px;
    pointer-events: none
}

@media (max-width: 630px) {
    .inputCustom ~ label {
        width: auto;
    }

    .inputCustom ~ label {
        font-size: 14px;
    }
}

.inputCustom:focus ~ label, .hasContent.inputCustom ~ label {
    top: -8px;
    font-size: 16px;
    color: #2a266b;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.areaBox{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.textarea {
    resize: none;
    width: 100%;
    height: 120px;
    border: 2px solid #ededed;
    padding: 16px 8px 8px 8px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    background-color: transparent;
    color: #272727;
    border-bottom: 2px solid #ededed;
    box-sizing: border-box;
}

.labelArea {
    font-size: 16px;
    color: #2a266b;
}
