.tooltipBlock {
    display: flex;
    gap: 10px;
    margin-right: 10px;
}

.tooltip {
    position: relative;
    display: inline-block
}

.styles-module_tooltip__mnnfp {
    width: auto!important;
}
