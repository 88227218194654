.header__switchLang {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 20px;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.header__switchLang:hover {
    color: #FBC8DF;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.header__switchLang svg path {
    fill: #fff;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.span {
    text-transform: uppercase;
   text-align: center;
}

.header__switchLang_box {
    display: flex;
    justify-content: center;
    align-items: center;
}
