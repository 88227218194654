.header__notificationBox {
    position: absolute;
    background-color: #fff;
    width: 425px;
    left: -200px;
    top: 56px;
    z-index: 100;
}


@media (max-width: 1270px) {
    .header__notificationBox {
        top: 46px;
        left: -200px;
    }
}

@media (max-width: 768px) {
    .header__notificationBox {
        left: 50%;
        right: -10px;
        width: auto;
        top: 50px;
    }
}

@media (max-width: 576px) {
    .header__notificationBox {
        left: -10px;
    }
}

.notiWrp {
    border: 1px solid #EDEDED;
    -webkit-filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08));
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08));
    overflow-y: auto;
    max-height: calc(100vh - 70px);
}

@media (max-width: 576px) {
    .notiWrp {
        max-height: none;
    }
}

.notiWrp__title {
    padding: 16px;
    text-align: left;
    font-family: "Roboto", "Gilroy", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #2a266b;
}

@media (max-width: 576px) {
    .notiWrp__title {
        font-size: 16px;
        padding: 10px;
    }
}

.menuOptions__link {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-family: "Gilroy", "Roboto", "Century Gothic", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #272727;
    padding: 12px 24px 12px 24px;
    background-color: #fff;
    border-bottom: 1px solid #EDEDED;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    border-left: 2px solid transparent;
}

.menuOptions__link:hover {
    border-left: 2px solid #2a266b;
    color: #2a266b;
    background-color: #FFF4F9;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s
}

.menuOptions__link.active {
    border-left: 2px solid #2a266b;
    color: #2a266b;
    background-color: #FFF4F9;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s
}


.menuOptions__link img {
    margin-right: 12px;
}

@media (max-width: 576px) {
    .menuOptions__link {
        font-size: 12px;
        padding: 12px 5px 12px 24px;
    }
}

.newNoti {
    width: 8px;
    height: 8px;
    background-color: #EA2525;
    border-radius: 50%;
    position: absolute;
    left: 5px;
    display: block;
}

/*animate show modal*/

/*setting profile*/
.headerMenuSettingProfile-enter {
    opacity: 0;
    top: 20px !important;
}

.headerMenuSettingProfile-enter-active {
    opacity: 1;
    top: 56px !important;
    transition: all 300ms ease-in 0s;
}

@media (max-width: 1270px) {
    .headerMenuSettingProfile-enter-active {
        top: 46px !important;
    }
}

@media (max-width: 768px) {
    .headerMenuSettingProfile-enter-active {
        top: 50px !important;
    }
}

.headerMenuSettingProfile-exit {
    opacity: 1;
    top: 50px !important;
}

.headerMenuSettingProfile-exit-active {
    opacity: 0;
    top: 0 !important;
    transition: all 400ms ease 0s;
}


/*notification profile*/
.headerNotification-enter {
    opacity: 0;
    top: 0 !important;
}

.headerNotification-enter-active {
    opacity: 1;
    top: 49px !important;
    transition: all 300ms ease-in 0s;
}

@media (max-width: 1270px) {
    .headerNotification-enter-active {
        top: 39px !important;
    }
}

@media (max-width: 768px) {
    .headerNotification-enter-active {
        top: 50px !important;
    }
}

.headerNotification-exit {
    opacity: 1;
    top: 50px !important;
}

.headerNotification-exit-active {
    opacity: 0;
    top: 0 !important;
    transition: all 300ms ease 0s;
}

/*main menu burger*/
.menuBurger-enter {
    top: -100% !important;
}

.menuBurger-enter-active {
    top: 0 !important;
    transition: all 400ms ease-in 0s;
}

.menuBurger-exit {
    top: 0 !important;
}

.menuBurger-exit-active {
    top: -100% !important;
    transition: all 400ms ease 0s;
    opacity: 0;
}

