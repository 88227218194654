
.header__logoLink {
    display: flex;
    height: 48px;
    width: auto;
    align-items: center;

}

.header__logoLink {
    z-index: 5;
}

.header__logoLink img {
    height: 100%;
    width: auto;
    -o-object-fit: contain;
    object-fit: contain;
}

@media (max-width: 1910px) {
    .header__logoLink {
        height: 40px;
    }
}

